import { ContactForm } from "components/forms/ContactForm"
import { Hero } from "components/Hero"
import { Layout } from "components/Layout"
import { Seo } from "components/Seo"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import { GetContactPageQuery } from "../../gatsby-graphql"


export type DataProps = GetContactPageQuery



const ContactPage: React.FC<PageProps<DataProps>> = ({ data }) => {
    const heroImageData = data.hero?.childImageSharp
    const contactDetails = data.contactDetails?.childMarkdownRemark?.frontmatter

    const images = useMemo(() => {
        return withArtDirection(getImage(heroImageData!.smallImage) as IGatsbyImageData, [
            {
                media: "(min-width: 75em)",
                image: getImage(heroImageData!.largeImage) as IGatsbyImageData
            },
            {
                media: "(min-width: 30em)",
                image: getImage(heroImageData!.mediumImage!) as IGatsbyImageData
            },
        ])
    }, [])

    return (
        <Layout pageClass="ContactPage">
            <Seo title="Contact" />

            <Hero
                title={'Contact'}
            >
                <GatsbyImage
                    image={images}
                    alt="Upolu to sua ocean trench"
                    style={{ width: "100%" }}
                    loading="eager" />
            </Hero>

            <div className="inner typography">
                <address className="ContactPage-contactDetails">

                    <div className="ContactPage-address">
                        <span><strong>Address:</strong></span>
                        {contactDetails?.address &&
                            contactDetails.address.map((line, i) =>
                                <span key={i}>{line?.addressLine}</span>
                            )}

                    </div>

                    <div className="direct-message">
                        <span><strong>Email:</strong></span>
                        {contactDetails?.contact?.emails &&
                            contactDetails.contact.emails.map((email, i) =>
                                <a href={`mailto:${email?.email}`} key={i}>{email?.email}</a>
                            )}

                        <span><strong>Phone:</strong></span>
                        <a href={`tel:${contactDetails?.contact?.phone?.split(" ").join("")}`} className="phone"> {contactDetails?.contact?.phone}</a>
                        <span><strong>Fax:</strong> </span>
                        <span className="fax">{contactDetails?.contact?.fax}</span>

                    </div>

                </address>
                <div className="ContactPage-formHolder">
                    <p className="center">Please complete the form to contact us:</p>
                    <ContactForm />
                </div>
            </div>
        </Layout>
    )
}


export default ContactPage

export const query = graphql`
    query getContactPage {
        hero: file(relativePath: {eq: "upolu_to-sua-ocean-trench-background.jpg"}) {
            childImageSharp {
                ...HeroImage
            }
        }
        contactDetails: file(relativePath: {eq: "settings.md"}) {
                childMarkdownRemark {
                    frontmatter {
                        contact {
                            phone
                            fax
                            emails {
                                email
                            }
                        }
                        address {
                            addressLine
                        }
                    }
                }
            }
    }
`