import { SubmitButton } from 'components/buttons/SubmitButton'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextareaInput } from './inputs/TextareaInput'
import { TextInput } from './inputs/TextInput'

export type ContactFormValues = {
    name: string,
    email: string,
    phone: string,
    message: string,
}

export const ContactForm: React.FC = () => {

    const { handleSubmit, formState, register, reset } = useForm<ContactFormValues>()
    const { errors, isSubmitting } = formState

    return (
        <form className='ContactForm' netlify-honeypot="bot-field" data-netlify="true" name="contact" method="post">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <TextInput
                label="Name"
                {...register("name", { required: "Name message is required." })}
                errors={errors.name?.message}
            />
            <TextInput
                label="Email"
                {...register("email", { required: "Email message is required." })}
                errors={errors.email?.message}
                type="email"
            />
            <TextInput
                label="Phone"
                {...register("phone")}
                errors={errors.phone?.message}
            />
            <TextareaInput
                label="Message"
                {...register("message", { required: "Message message is required." })}
                errors={errors.message?.message}
            />
            <SubmitButton isLoading={isSubmitting} />
        </form>
    );
}