import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { ChangeHandler } from "react-hook-form/dist/types";

export type TextareaInputProps = {
    name: string,
    label: string,
    className?: string,
    errors?: string | null,
    defaultValue?: string,
    onBlur: ChangeHandler,
    onChange: ChangeHandler
}

export const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaInputProps>(({ label, name, errors, onChange, onBlur, className = "", defaultValue = "" }, ref) => {

    const [isFocused, setIsFocused] = useState(defaultValue ? true : false)
    const [inputEle, setInputEle ] = useState<HTMLTextAreaElement | null>(null)

    const inputRef= useCallback((ref: HTMLTextAreaElement | null) => {
        if(ref !== null) setInputEle(ref)
    },[])


    const handleFocus = () => {
        setIsFocused(true)
    }
    const handleBlur = (e: React.FocusEvent) => {
        inputEle?.value ? setIsFocused(true) : setIsFocused(false)
        if(onBlur) onBlur(e)
    }
    const handleChange = (e: React.ChangeEvent) => {
        inputEle?.value ? setIsFocused(true) : setIsFocused(false)

    }
    useEffect(() => {
        if(defaultValue || inputEle?.value) setIsFocused(true)
    }, [inputEle, defaultValue])
    
    return (
        <div className={`input-container ${isFocused ? 'focused' : ''}`}>
            <label htmlFor={name}>{label}</label>
            <textarea
                name={name}
                id={name}
                ref={e => {
                    if (typeof ref === 'function') { ref(e)}
                    inputRef(e)
                }}
                className={className || name}
                onFocus={handleFocus}
                onChange={e => { if (onChange) onChange(e);  handleChange(e)}}
                onBlur={e => handleBlur(e)}
                defaultValue={defaultValue}
            />
            <div className="error">{errors && errors}</div>
        </div>
    );
})
