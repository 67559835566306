import React from 'react';
import { EventDatetime } from './EventDatetime';
import { NewsDatetime } from './NewsDatetime';

export type HeroProps = {
    title?: string,
    date?: string
    endDate?: string
    timeString?: string
}

export const Hero: React.FC<HeroProps> = ({ title, date, endDate, children }) => (
    <div className='Hero'>
        <div className="Hero-overlay">
            <div className="Hero-textWrapper">
                <h1>{title}</h1>
                {endDate && date ? <EventDatetime startDate={date} endDate={endDate} /> : ''}
                {!endDate && date ? <NewsDatetime date={date} /> : ''}
            </div>
        </div>
        {children}
    </div>
);