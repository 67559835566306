import React from 'react'
import { ButtonSpinner } from "components/ButtonSpinner"

export type SubmitButtonProps = {
    isLoading?: boolean,
    text?: string,
    disabled?: boolean
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({isLoading = false, text = "Submit", disabled = false}) => (
    <button type="submit" className="SubmitButton ep-1 btn" disabled={isLoading || disabled}>
        {isLoading ? <ButtonSpinner /> : text}
    </button>
)