import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { ChangeHandler } from "react-hook-form/dist/types";

export type TextInputProps = {
    name: string,
    label: string,
    errors?: string | null,
    type?: string,
    className?: string,
    defaultValue?: string,
    onBlur?: ChangeHandler,
    onChange?: ChangeHandler,
    isFree?: boolean //Change this variable name, is for when there is no <Form> attached, when we just want to read the value of the input
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ label, name, errors, onChange, onBlur, type = "text", className = "", defaultValue = "", isFree = false }, ref) => {

    const [isFocused, setIsFocused] = useState(defaultValue ? true : false)
    const [inputEle, setInputEle ] = useState<HTMLInputElement | null>(null)

    const inputRef= useCallback((ref: HTMLInputElement | null) => {
        if(ref !== null) setInputEle(ref)
    },[])

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = (e: React.FocusEvent) => {
        inputEle?.value ? setIsFocused(true) : setIsFocused(false)
        if (onBlur) onBlur(e)
    }
    const handleChange = (e: React.ChangeEvent) => {
        inputEle?.value ? setIsFocused(true) : setIsFocused(false)

    }
    useEffect(() => {
        if (defaultValue || inputEle?.value) setIsFocused(true)
    }, [inputEle, defaultValue])


    return (
        <div className={`input-container ${isFocused ? 'focused' : ''}`}>
            <label htmlFor={name}>{label}</label>
            <input type={type}
                name={name}
                id={name}
                ref={e => {
                    if (typeof ref === 'function' && !isFree) { ref(e) }
                    if (ref && typeof ref != 'function' && isFree) { ref.current = e }
                    inputRef(e)
                }}
                className={className || name}
                onFocus={handleFocus}
                onChange={e => { if (onChange) onChange(e);  handleChange(e)}}
                onBlur={e => handleBlur(e)}
                defaultValue={defaultValue}
            />
            <div className="error">{errors && errors}</div>
        </div>
    );
})